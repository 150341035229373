<template>
  <div class="base-list-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-select
            v-model="searchForm.isUse"
            placeholder="是否使用"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in isUseOptions"
              :key="index"
              :label="item.name"
              :value="item.isUse"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.name"
            placeholder="供应商名字"
            clearable
          />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button
            v-if="isAuth('cc:org:save')"
            type="primary"
            @click="addOrUpdateHandle()"
            >新增</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        width="120"
        label="供应商名称"
      ></el-table-column>

      <el-table-column
        prop="isUse"
        header-align="center"
        align="center"
        width="120"
        label="是否使用"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isUse"
            @change="changeUse(scope.row.id, scope.row.isUse)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="creditCode"
        header-align="center"
        align="center"
        width="150"
        label="统一社会信用代码"
      ></el-table-column>
      <el-table-column
        prop="contact"
        header-align="center"
        align="center"
        width="120"
        label="联系人"
      ></el-table-column>
      <el-table-column
        prop="contactMobile"
        header-align="center"
        align="center"
        width="120"
        label="联系电话"
      ></el-table-column>
      <el-table-column
        prop="address"
        header-align="center"
        align="center"
        width="180"
        label="地址"
      ></el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        width="180"
        label="创建时间"
      ></el-table-column>
      <el-table-column
        prop="createdBy"
        header-align="center"
        align="center"
        width="120"
        label="创建者"
      ></el-table-column>
      <el-table-column
        width="180"
        header-align="center"
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('cc:org:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, false)"
            >编辑</el-button
          >
          <el-button
            v-if="isAuth('cc:org:id')"
            type="text"
            size="small"
            @click="showDetails(scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('cc:org:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './org-add-or-update';
import { normal } from '@/mixins';

export default {
  mixins: [normal],
  data() {
    return {
      searchForm: {
        isUse: true,
        name: '',
      },
      isUseOptions: [
        {
          isUse: true,
          name: '是',
        },
        {
          isUse: false,
          name: '否',
        },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/cc/org/page',
        method: 'get',
        params: {
          page: this.pageIndex,
          perPage: this.pageSize,
          ...this.searchForm,
          type: 0,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.totalPage = data.data.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 查看详情
    showDetails(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, true);
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(ids) {
      //       id
      // ? [id]
      // : this.dataListSelections.map((item) => {
      //     return item.id;
      //   });
      this.$confirm(`确定对[id=${ids}]进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/cc/org/ids/delete',
            method: 'post',
            data: { ids },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
    changeUse(id, isUse) {
      this.$http({
        url: `/cc/org/ids/update/boolean`,
        method: 'post',
        data: [
          {
            id: id,
            isUse: isUse,
          },
        ],
        after: () => {
          this.getDataList();
        },
      });
    },
  },
};
</script>
